<template>
<div class="app-auth-body mx-auto" style="padding-top:8vh; height:52vh">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-4">

                <div class="text-center">
                    <div class=" mb-4"><a class="app-logo"><img class="logo-icon me-2" src="/logo/logo_brayan.png" alt="logo" style="width:100px;height:100px"></a></div>
                    <h2 class="auth-heading text-center mb-5">Log in to Web Brayan</h2>
                    <div class="auth-form-container text-start">
                        <form class="auth-form login-form" @submit.prevent="login">
                            <div class="email mb-3">
                                <label class="sr-only" for="signin-email">Nomor Hp</label>
                                <input id="signin-email" v-model="user.hp" name="signin-email" type="text" class="form-control signin-email" placeholder="Masukan Nomor Hp" required="required">
                            </div>
                            <!--//form-group-->
                            <div class="password mb-3">
                                <label class="sr-only" for="signin-password">Password</label>
                                <input v-model="user.password" id="signin-password" name="signin-password" type="password" class="form-control signin-password" placeholder="Password" required="required">
                                <div class="extra mt-3 row justify-content-between">
                                    <div class="col-6">

                                    </div>
                                    <!--//col-6-->
                                    <div class="col-6">
                                        <div class="forgot-password text-end">
                                            <a href="/lupapw">Forgot password?</a>
                                        </div>
                                    </div>
                                    <!--//col-6-->
                                </div>
                                <!--//extra-->
                            </div>
                            <!--//form-group-->
                            <div class="text-center">
                                <button type="submit" class="btn app-btn-primary w-100 theme-btn mx-auto">Log In</button>
                            </div>
                        </form>
                    </div>
                    <!--//auth-form-container-->

                    <!--//auth-body-->
                </div>
            </div>
        </div>

    </div>
</div>

<div class="container text-center" style="margin-top: 31vh; padding-bottom:0px; height:2vh">
    <!--/* This template is free as long as you keep the footer attribution link. If you'd like to use the template without the attribution link, you can buy the commercial license via our website: themes.3rdwavemedia.com Thank you for your support. :) */-->
    <small class="copyright">Designed with <i class="fas fa-heart" style="color: #fb866a;"></i> by <a class="app-link" href="http://golet.co.id" target="_blank">Golet Digital Solustion</a> for developers</small>

</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Login',

    data() {
        return {
            //state loggedIn with localStorage
            loggedIn: localStorage.getItem('loggedIn'),
            //state token
            token: localStorage.getItem('token'),
            //state user
            user: [],
            //state validation
            validation: [],
            //state login failed
            loginFailed: null
        }
    },
    methods: {

        login() {
            if (this.user.hp && this.user.password) {
                // axios.get('http://brayandiesel.com/sanctum/csrf-cookie')
                //     .then(response => {

                //debug cookie
                // console.log(response)
                axios.post(this.globalApiUrl + 'api/login-gudang', {
                    no_hp: this.user.hp,
                    password: this.user.password
                }).then(res => {

                    //debug user login
                    console.log(res)

                    if (res.data.meta.status == 'success') {

                        //set localStorage
                        localStorage.setItem("loggedIn", "true")
                        
                        //set localStorage Token
                        localStorage.setItem("token", res.data.data.token_type + " " + res.data.data.access_token)
                         localStorage.setItem("cabang", res.data.data.user.cabang_id);
                        //change state
                        this.loggedIn = true
                        console.log(this.loggedIn)
                        //redirect dashboard
                        // window.location.reload();
                        return this.$router.push({
                            name: 'dashboard.index'
                        })

                    } else {

                        //set state login failed
                        this.loginFailed = true

                    }

                }).catch(error => {
                     this.$toast.show('No Hp atau Password Salah', {
                            type: 'error',
                            position: "top",
                            duration: 1000,
                            max: 1,
                        });
                })

                // })
            }

            this.validation = []

            if (!this.user.email) {
                this.validation.email = true
            }

            if (!this.user.password) {
                this.validation.password = true
            }

        }
    },

    //check user already logged in
    mounted() {
        if (this.loggedIn) {
            if (this.token) {
                return this.$router.push({
                    name: 'dashboard.index'
                })
            } else {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
            }
        }
    }
}
</script>
